<template>
  <h2 class="text-center mt-0 font-normal">Log in</h2>
  <form class="block" novalidate @submit.prevent="onSubmit">
    <div class="mb-4 relative">
      <div class="p-float-label p-input-icon-left w-full">
        <i class="pi pi-envelope" />
        <InputText
          id="email"
          type="text"
          v-model="$v.email.$model"
          :class="{ 'p-invalid': $v.email.$errors.length }"
          :disabled="isLoading"
          class="p-inputtext-lg w-full" />
        <label for="email">Email</label>
      </div>
      <p
        class="text-xs text-red-500 m-0 m-1 absolute"
        v-for="error in $v.email.$errors"
        :key="error.$uid">
        {{ error.$message }}
      </p>
    </div>
    <div class="mb-4 relative">
      <div class="p-input-icon-left w-full">
        <i class="pi pi-lock-open" />
        <InputText
          id="password"
          type="password"
          v-model="$v.password.$model"
          class="p-inputtext-lg w-full"
          :disabled="isLoading"
          :class="{ 'p-invalid': $v.password.$errors.length }"
          placeholder="······" />
      </div>
      <p
        class="text-xs text-red-500 m-0 mt-1 absolute"
        v-for="error in $v.password.$errors"
        :key="error.$uid">
        {{ error.$message }}
      </p>
    </div>
    <div class="flex mb-4">
      <div class="field-checkbox mb-0">
        <Checkbox inputId="binary" v-model="form.remember" :binary="true" :disabled="isLoading" />
        <label for="binary">Remember me</label>
      </div>
      <Btn
        class="p-button-text font-bold p-0 ml-auto"
        label="Forgot password?"
        :disabled="isLoading"
        @click="$router.push({ name: 'ResetPass' })" />
    </div>
    <div class="flex align-items-center justify-content-center">
      <Btn type="submit" class="p-button-lg font-bold" label="Sign in" :loading="isLoading" />
    </div>
  </form>
</template>
<script setup>
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import Btn from "primevue/button";
import { reactive, ref } from "vue";

import { useRoute, useRouter } from "vue-router";

import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { passwordRules } from "@/utils/validators";
import { useToast } from "primevue/usetoast";
import { useUserStore } from "@/store";
import { loginRequest } from "@/api/authService";

const $userStore = useUserStore();
const $router = useRouter();
const $route = useRoute();
const $toast = useToast();

const rules = {
  email: { required, email, $lazy: true },
  password: { ...passwordRules, $lazy: true },
};
const form = reactive({
  email: $route.query.email,
  password: "",
  remember: false,
});

let isLoading = ref(false);

const $v = useVuelidate(rules, form);

const onSubmit = async () => {
  if (isLoading.value) {
    return;
  }
  $v.value.$touch();
  if ($v.value.$invalid) {
    return;
  }
  try {
    isLoading.value = true;
    const { email, name } = await loginRequest(form);
    $userStore.$patch({ email, name });
    await $router.push({ name: "AdminLayout" });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      $toast.add({
        severity: "error",
        summary: "Invalid credentials",
        detail: "Wrong email or password",
        life: 5000,
      });
    }
  } finally {
    isLoading.value = false;
  }
};
</script>
<style scoped></style>
